.button-group {
    display: flex;
    
    margin-bottom: 1rem;
    border-bottom: 1px solid lightgray;

    div {
        margin-bottom: 1rem;
    }

    @include media-breakpoint-down(md) {
        flex-flow: wrap;
    }

    .btn {
        width: 100%;

        display: flex;
        text-align: left;

        margin-right: 1rem;
        min-width: 125px;

        .btn-text {
            width: 100%;
            text-align: center;
            padding-top: 0.4rem;
        }

        @include media-breakpoint-down(md) {
            //width: calc(50% - 0.25rem) !important; //0.25 instead of 0.5
            min-width: 100px;
            margin-right: 0.5rem;
            overflow: hidden;
            padding-right: 2px;
            padding-left: 0;

            &:nth-child(even) {
                margin-right: 0;
            }

            .btn-label {
                left: 0;
            }
            .btn-text {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &.last-child,
        &:last-child {
            margin-right: 0;
        }
    }
}
