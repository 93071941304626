.spinner-wrapper {
  align-items: center !important;
  display: flex;
  height: 75px;
  justify-content: center !important;
  width: 100%;

  .ball-clip-rotate-multiple {
      div {
        border-color: $primary transparent !important;
      }
  }
}
