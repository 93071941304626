#calendar {
  display: flex;
  height: 100%;
}

.fc.fc-unthemed {
  margin-left: -$content-padding;
  margin-right: -$content-padding;

  .fc-toolbar {
    padding-left: $content-padding;
    padding-right: $content-padding;

    h2 {
      font-weight: 700;
      font-size: 18px;
      color: $subsidiary;
    }

    .fc-button {
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      padding-left: 0.5em;
      padding-right: 0.5em;

      > .fc-icon {
        font-size: 1.3em;
        position: relative;
        top: -1px;
      }
    }

    &.fc-header-toolbar {
      margin-bottom: 15px;
    }
  }

  .fc-button-group {
    box-shadow: 0 1px 2px rgba($gray, 0.45);
  }

  .fc-button {
    background-color: $white;
    border-color: $gray-lighter;
    color: $gray-darker;
    font-weight: 600;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: $gray-lightest;
      border-color: $gray;
      color: $gray-darker;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($gray, 0.8) !important;
    }

    &.fc-button-active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }

  th,
  td,
  thead,
  tbody {
    border-color: rgba($primary-dark, 0.1);
  }

  td,
  th {
    &:first-child {
      border-left-width: 0;
    }

    &:last-child {
      border-right-width: 0;
    }

    &.fc-today {
      background-color: rgba($primary-light, 0.05) !important;
      border-right-color: rgba($primary, 0.3);
      border-left-color: rgba($primary, 0.3);
    }
  }

  .fc-divider {
    background-color: $primary-light;
    border: 0;
    box-shadow: 0 1px 2px rgba($subsidiary, 0.2);
  }

  .fc-time-grid {
    .fc-now-indicator-line {
      border-top-width: 0;
      height: 2px;
      background-color: $danger-dark;
      box-shadow: 0 1px 2px rgba($danger-dark, 0.4);
      right: calc(7% - 2px);
      left: -2px;
    }

    .fc-now-indicator-arrow {
      left: 2px;
      border: 0;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: $danger-dark;
      margin-top: -5px;
      box-shadow: 0 1px 2px rgba($danger-dark, 0.4);

      &::before {
        display: block;
        margin-top: 5px;
        width: 22px;
        height: 2px;
        content: "";
        background-color: $danger-dark;
        box-shadow: 0 1px 2px rgba($danger-dark, 0.4);
      }

      &::after {
        position: absolute;
        z-index: -1;
        top: 5px;
        display: none;
        width: 17px;
        height: 0;
        content: "";
        width: 100vw;
        border-top: 2px dashed rgba($danger-dark, 0.25);
      }

      //border-width: 8px 0 8px 11px;
      //margin-top: -8px;
      //border-color: $danger-dark;
      //border-top-color: transparent;
      //border-bottom-color: transparent;
    }
  }

  .fc-head {
    td,
    th {
      border-top-width: 0;
      font-size: 14px;
      color: $white;
      background-color: $primary;
      padding-top: 0.1em;
      padding-bottom: 0.1em;
      border-width: 0;
    }

    th {
      font-weight: 600;
    }

    .fc-row {
      border-width: 0 !important;
    }
  }

  .fc-bg {
    font-size: 14px;
    color: $subsidiary-lighter;
  }

  .fc-widget-content {
    border-bottom-width: 0;
  }

  .fc-slats {
    font-size: 14px;
    color: $subsidiary-lighter;

    tr {
      &:nth-child(odd) {
        background-color: rgba($primary, 0.05);
      }

      &:last-child {
        td,
        th {
          border-bottom-width: 0;
        }
      }
    }

    .fc-axis {
      background-color: transparent;
    }
  }

  .fc-time-grid {
    .fc-event-container {
      margin-right: 1px;
      margin-left: 1px;
    }
  }

  .fc-event {
    border-width: 0;
    border-style: solid;
    border-radius: 0;
    z-index: 0;
    border-left-width: 5px;
    padding-left: 3px;
    background-color: rgba($white, 0.9);
    color: $body-color;

    .fc-resizer {
      font-size: 12px;
      background-color: rgba($primary-lightest, 0.6);
      color: $primary-dark;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 2px;
    }

    .fc-event-background {
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: none;
    }

    &.fc-free-event {
      background-color: rgba($white, 0.7);

      .fc-event-background {
        display: block;
      }
    }

    .fc-time {
      white-space: nowrap;
      overflow: hidden;

      > span {
        font-size: 11px;
        font-weight: 600;
        color: rgba($body-color, 0.55);
      }
    }

    .fc-status {
      position: relative;
      top: 2px;
      display: inline-block;
      margin-left: 4px;
      margin-right: 4px;
      height: 11px;
      min-width: 5px;
      border-radius: 11px;
      padding-left: 3px;
      padding-right: 3px;
      line-height: 11px;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .fc-status-code {
      display: block;
      letter-spacing: 0.5px;
      max-width: 30px;
      white-space: nowrap;
      overflow: hidden;
    }

    .fc-title {
      font-weight: 600;
      font-size: 14px;
      color: $body-color;
      line-height: 1.2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .fc-time-grid-event {
    margin-bottom: 2px;
  }
}

tr#fullcalendar-sub-header {
  a {
    color: $white;
  }
}
